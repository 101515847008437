.login-block {
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    height: 100%;
}
.splashblock{
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    height: 100%;
}

.splashblock .auth-box {
    max-width: 450px;
}

.splashImg {
    object-fit: cover;
    background-size: cover;
}